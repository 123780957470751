import React, { Component } from 'react';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import find from 'lodash/find';

import { H4, Radiobutton, PopOver } from '../../../components/index.js';
import location from '../../../constants/json/radiobuttons/location.json';
import ServiceRequest from '../../../constants/json/radiobuttons/serviceRequest.json';

class LabLocation extends Component {
  componentDidMount() {
    const { displaySubmitButtonTrf, displayClearButtonTrf } = this.props;
    displaySubmitButtonTrf(false);
    displayClearButtonTrf(true);
  }

  locationObjSort() {
    const locationSort = location.map(LocationData => {
      const locationObjData = {
        lab: LocationData.lab,
        id: LocationData.id,
        location: LocationData.location
          .filter(data => data.isLocationAvailable === true)
          .sort((a, b) => a.area.localeCompare(b.area))
      };
      return locationObjData;
    });
    return locationSort;
  }

  render() {
    const { sucessFill, labInfoStates, isPartialEdit } = this.props;
    const locationObj = this.locationObjSort();
    return (
      <form className="form">
      <H4 className={sucessFill ? 'error-text' : 'error-hide'}>
      Location and Service Requested Fields are Mandatory
      <span className="astericks">&#42;</span>
      </H4>
      <div className="p2-sm">
      <H4 className="text-color-accent u-bold page-title-has-back sub-heading">LAB LOCATION</H4>
      </div>
      <div className=" ncss-col-sm-12 inline-flex-display u-bold pl0-sm">
      <div className="ncss-col-sm-2 description u-bold">
      Select Location <span className="astericks ml-neg3">&#42;</span>
      </div>
      
      <div className="ncss-col-sm-8">
      {locationObj.length > 0 ? locationObj.map(locationData => (
        <span key={locationData.lab}>
        <div key={locationData.lab} className="ncss-col-sm-4  u-bold pb1-sm">
        <span className="ncss-radio-container">
        <Field
          type="radio"
          component={Radiobutton}
          name="lab"
          value={locationData.id}
          disabled={isPartialEdit}
          checked={false}
          label={locationData.lab}
          id={locationData.id}
          isIcon={false}
          translate='no'
        />
        </span>
        {labInfoStates && labInfoStates.lab === locationData.id  ? (
        <PopOver>
          {find(locationObj, ['id', labInfoStates.lab]).location.length > 0 ? find(locationObj, ['id', labInfoStates.lab]).location.map(data => (
           data.isLocationAvailable === true && (
          <div key={data.area} className=" ncss-radio-container">
          <Field
          type="radio"
          component={Radiobutton}
          disabled={isPartialEdit}
          name={`location${locationData.id}`}
          label={`${data.area}, ${data.country}`}
          value={data.id}
          id={data.id}
          checked={false}
          isIcon={false}
          translate='no'
          
          />
          </div>)
          )) : null}
        </PopOver>
        ) : null}
        </div>
        </span>
      )) : null}
      </div>
      </div>
      <div className="ncss-col-sm-12 description u-bold pt4-sm">
      Service Requested <span className="astericks ml-neg3">&#42;</span>
      </div>
      <div className=" ncss-col-sm-12 inline-flex-display">
      <div className="ncss-col-sm-12  ncss-form-group">
      {ServiceRequest.length > 0 ? ServiceRequest.map(data => (
        <div key={data.label} className="ncss-col-sm-4 ncss-radio-container">
        <Field
        type="radio"
        component={Radiobutton}
        name="serviceRequested"
        value={data.label}
        disabled={isPartialEdit}
        checked={false}
        label={data.label}
        id={data.id}
        isIcon={false}
        />
        <span className="pl6-sm radio-sub-info">{data.sub}</span>
        </div>
      )) : null}
      </div>
      </div>
      </form>
    );
  }
}
LabLocation.propTypes = {
  sucessFill: PropTypes.bool,
  displaySubmitButtonTrf: PropTypes.func,
  displayClearButtonTrf: PropTypes.func,
  labInfoStates: PropTypes.object
};
const mapStateToProps = state => ({
  labInfoStates: getFormValues('labLocation')(state)
});
export default reduxForm({
  form: 'labLocation',
  initialValues: {
    serviceRequested: 'Regular'
  },
  destroyOnUnmount: false,
  enableReinitialize: true
})(
  connect(
    mapStateToProps,
    null
  )(LabLocation)
);